import React from 'react';
import { Layout, Menu, Button, Input, Icon } from 'antd';
import './App.less'
import { useTranslation } from 'react-i18next'
import Router from './Router'

const { Header, Footer, Content } = Layout;

const App: React.FC = () => {
  const { t, i18n } = useTranslation()
  return (
    <div>
      <Layout>
        <Header style={{
          padding: "0 20%"
        }}>
          <div className="logo" />
          <Menu
            theme="dark"
            mode="horizontal"
            defaultSelectedKeys={['2']}
            style={{ lineHeight: '64px', float: 'left' }}
          >
            <Menu.Item key="1">{t('Home')}</Menu.Item>
            <Menu.Item key="2">{t('News')}</Menu.Item>
            <Menu.Item key="3">{t('Parents')}</Menu.Item>
            <Menu.Item key="4">{t('Educator')}</Menu.Item>
            <Menu.Item key="5">{t('Forum')}</Menu.Item>
          </Menu>

          <div style={{
            display: "none"
          }} className="download-button">
            <Button style={{
              borderColor: "#ffffff",
              color: "#1890ff",
              border: "none",
              boxShadow: "none"
            }} shape="round" size="default">
              {t('Download')}
            </Button>
          </div>
        </Header>
        <Content>
          <Router />
        </Content>
        <Footer style={{
          padding: "0"
        }}>
          <div className="footer-sider">
            <div
              style={{
                height: "90px",
                lineHeight: "136px",
              }}
            >{t('SUBSCRIBE TO NEWSLETTER')}</div>
            <div style={{
              padding: "0 40%"
            }}>
              <Input
                style={{
                  borderRadius: "0"
                }}
                addonAfter={<Icon type="mail" theme="twoTone"/>}
                placeholder={t('You Email Address')}
                size="large"
              />
            </div>
            <div style={{
              fontWeight: "bold",
              fontSize: "24px",
              height: "85px",
              lineHeight: "85px",
            }}>
              BELL PLANET
            </div>
            <div>
              8951 DOLVAN ROAD - NEWYORK - PHONE +44 023 456 789
            </div>
          </div>
          <div className="copyrigth">
            © COPYRIGHT 2019 腾语科技 备案号：粤ICP备17079195号-6
          </div>
        </Footer>
      </Layout>
    </div>
  );
}

export default App;