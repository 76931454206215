import React from 'react';
import { useState, MouseEvent, useRef } from 'react';
import { Carousel } from 'antd';
import './Index.css'
import Banner1 from '../../resources/banner.png'
import VideoCtlPng from '../../resources/btn_play.png'

// @ts-ignore
import Mp4 from '../../resources/bellcode.mp4'

const Index: React.FC = () => {
    const [videoPlayed, setVideoPlayed] = useState(false)
    const demoVideo = useRef<HTMLVideoElement>(null);

    const videoClickHandle = (event: MouseEvent) => {
        event.preventDefault();
        (demoVideo.current as HTMLVideoElement).play()
        setVideoPlayed(true)
    }

    const moreClickHandle = (evnet: MouseEvent) => {
        evnet.preventDefault();

        // Redirect game url
        // window.location.href = ""
    }

    return (
        <div className="Page-Index">
            <div>
                <Carousel dots={false} style={{
                    lineHeight: "0"
                }}>
                    <div className="carousel" style={{
                        position: "relative",
                        lineHeight: "0"
                    }}>
                        <div className="float-sider">
                            <span>CREATIVE DIGITAL AGENCY</span>
                            <span>FOR YOUR NEXT <span style={{
                                background: "#1890ff",
                                display: "inline",
                                padding: "0 20px"
                            }}>WEB PROJECT</span></span>
                            <button onClick={moreClickHandle}>EXPLORE MORE</button>
                        </div>
                        <div className="mock"></div>
                        <img style={{
                            width: "100%"
                        }} src={Banner1} />
                    </div>
                </Carousel>
            </div>
            <div className="demo-video">
                <div>
                    <h1>
                        Expert legal advice <br />
                        for your cibfudebce in the
                    </h1>
                    <h6>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent molestie <br />
                        nec nisl eget scelerisque. Quisque placerat sucipit eros, eu tincidunt tellus <br />
                        blandit vel. Donec pellentesque dapibus inter dum.
                    </h6>
                    <h4>Just call us.</h4>
                    <h3>202-36202496</h3>
                </div>
                <div className="video" style={{
                    marginLeft: "4%"
                }}>
                    <video onClick={videoClickHandle} ref={demoVideo} className="video" src={Mp4} controls={true} loop={true}>
                        您的浏览器不支持 video 标签。
                    </video>
                    {!videoPlayed && <img onClick={videoClickHandle} src={VideoCtlPng} className="video-ctl" />}
                </div>
            </div>
            <div className="works">
                {/* works */}
            </div>
        </div>
    );
}

export default Index;