import React from 'react'
import Index from './pages/Index/Index'
import { Route, BrowserRouter as Router } from 'react-router-dom'
const MRouter: React.FC = () => {
    return (
        <Router>
            <Route path="/" component={Index}></Route>
        </Router>
    )
}

export default MRouter